import GAListener from 'components/GAListener';
import { EmptyLayout, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/Login';
import EsqueciSenha from 'pages/EsqueciSenha';
import RecuperarSenha from 'pages/RecuperarSenha';
import NovaSenha from 'pages/NovaSenha';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import { isAuthenticated } from './auth';

const Home = React.lazy(() => import('pages/Home'));
const Sindicalizados = React.lazy(() => import('pages/Sindicalizados'));
const DetalhesSindicalizados = React.lazy(() => import('pages/Sindicalizados/Detalhes'));
const CadastroSindicalizados = React.lazy(() => import('pages/Sindicalizados/Cadastro'));
const Sindicatos = React.lazy(() => import('pages/Sindicatos'));
const CadastroSindicatos = React.lazy(() => import('pages/Sindicatos/Cadastro'));
const DetalhesSindicatos = React.lazy(() => import('pages/Sindicatos/Detalhes'));
const Eventos = React.lazy(() => import('pages/Eventos'));
const DetalhesEventos = React.lazy(() => import('pages/Eventos/Detalhes'));
const Juridico = React.lazy(() => import('pages/Juridico'));
const Agenda = React.lazy(() => import('pages/Agenda'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const PrivateRoute = ({ component: Component, ...rest}) => (
  <Route {...rest} render={props => (
    isAuthenticated() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: props.location}}} />
    )
  )} />
);

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <PrivateRoute exact path="/" component={props => (
                <AuthPage {...props} />
              )}  render={()=>(<Redirect to="/login"/>)} 
            />

            {/* ACESSO SEM LOGIN */}
            <Route exact path="/login" layout={EmptyLayout} component={props => ( <AuthPage {...props} /> )} />
            <Route exact path="/esqueci-senha" layout={EmptyLayout} component={props => ( <EsqueciSenha {...props} /> )} />
            <Route exact path="/recuperar-senha" layout={EmptyLayout} component={props => ( <RecuperarSenha {...props} /> )} />
            <Route exact path="/nova-senha" layout={EmptyLayout} component={props => ( <NovaSenha {...props} /> )} />
            
            {/* ACESSO SOMENTE COM LOGIN */}
            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <PrivateRoute exact path="/home" component={Home} />
                <PrivateRoute exact path="/sindicalizados" component={Sindicalizados} />
                <PrivateRoute exact path="/sindicalizados/detalhes" component={DetalhesSindicalizados} />
                <PrivateRoute exact path="/sindicalizados/cadastro" component={CadastroSindicalizados} />
                <PrivateRoute exact path="/sindicatos" component={Sindicatos} />
                <PrivateRoute exact path="/sindicatos/detalhes" component={DetalhesSindicatos} />
                <PrivateRoute exact path="/sindicatos/cadastro" component={CadastroSindicatos} />
                <PrivateRoute exact path="/eventos" component={Eventos} />
                <PrivateRoute exact path="/eventos/detalhes" component={DetalhesEventos} />
                <PrivateRoute exact path="/juridico" component={Juridico} />
                <PrivateRoute exact path="/agenda" component={Agenda} />
              </React.Suspense>
            </MainLayout>
            <Redirect to="/login" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);