import React from 'react';

function Sindicatos(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30.818" height="30" viewBox="0 0 30.818 30" fill="none" {...props}>
            <g id="Grupo_2398" data-name="Grupo 2398" transform="translate(-1057.307 5085.626)">
                <path id="Caminho_1022" data-name="Caminho 1022" d="M1074.323-5085.2h0m.071.063c.286.072.575.147.856.22.656.168,1.335.342,2.006.5,2.569.616,5.182,1.24,7.709,1.843l2.685.641c-.631.147-1.271.3-1.891.445-1.621.382-3.3.778-4.948,1.146a22.559,22.559,0,0,1-4.893.507h-.351c-1.836,0-3.458-.036-4.96-.109a25.531,25.531,0,0,1-4.655-.816l-.562-.134c-1.214-.288-2.449-.577-3.644-.856l-.768-.18,1.261-.3,3.391-.816,8.442-2.034a.644.644,0,0,0,.147-.055h.177m.061-.491h-.374a.55.55,0,0,1-.127.068q-5.916,1.427-11.832,2.851l-3.179.77c.157.057.234.095.316.114,2,.472,4.011.938,6.015,1.412a29.277,29.277,0,0,0,5.306.963c1.659.081,3.322.109,4.984.109h.352a23.22,23.22,0,0,0,5-.52c2.3-.512,4.593-1.065,6.888-1.6.6-.141,1.2-.287,1.881-.449-.17-.06-.25-.1-.333-.117q-5.992-1.431-11.983-2.863c-.975-.234-1.944-.491-2.916-.737Z" transform="translate(-1.586)" fill="#fcfdfd"/>
                <path id="Caminho_1023" data-name="Caminho 1023" d="M1509.437-4851l-.044,2.252-.054,2.721h-2.031l-.08-3.719L1507.2-4851h2.236m.5-.491H1506.7c.043,2,.086,3.969.129,5.955h2.993l.117-5.955Z" transform="translate(-435.603 -226.953)" fill="#fcfdfd"/>
                <path id="Caminho_1024" data-name="Caminho 1024" d="M1626.567-4860.729c0,.045,0,.091,0,.135,0,.476,0,.968,0,1.448-.013.652-.032,1.315-.051,1.956-.013.464-.027.942-.039,1.414-.6.068-1.216.142-1.809.213l-.2.023q0-.208,0-.413c0-.726,0-1.413,0-2.107.008-.892.024-1.71.052-2.5.661-.064,1.333-.116,1.986-.167l.06,0m-2.042.034h0m0,.044h0m2.533-.609c-.951.076-1.891.144-2.828.238-.077.007-.19.2-.194.308q-.048,1.325-.057,2.65c-.008,1.006,0,2.012,0,3.078.917-.109,1.817-.22,2.718-.32.235-.027.263-.165.268-.358.027-1.157.07-2.314.093-3.471.014-.7,0-1.411,0-2.125Z" transform="translate(-549.278 -217.482)" fill="#fcfdfd"/>
                <path id="Caminho_1025" data-name="Caminho 1025" d="M1393.668-4860.881h.006c.679.057,1.379.117,2.068.187.009,1.556.009,3.09.008,4.71v.309l-.037,0c-.624-.074-1.267-.149-1.9-.235-.05-1.518-.091-3.063-.13-4.562,0-.135-.007-.271-.01-.406m-.269-.514c-.067,0-.139.01-.236.014.051,1.916.1,3.8.161,5.693a.361.361,0,0,0,.247.238c.9.123,1.8.223,2.67.327,0-1.978,0-3.9-.01-5.83,0-.073-.138-.2-.222-.208-.853-.089-1.707-.161-2.561-.233l-.049,0Z" transform="translate(-325.551 -217.351)" fill="#fcfdfd"/>
                <path id="Caminho_1026" data-name="Caminho 1026" d="M1937.886-4943.937c-.015.209-.029.413-.046.617-.087,1.058-.18,2.184-.284,3.324-.827.318-1.649.626-2.515.95.021-.49.041-.956.065-1.424.017-.344.036-.694.055-1.033.032-.576.064-1.171.088-1.759.714-.17,1.434-.36,2.13-.543l.506-.133m-2.63.608h0m3.164-1.227c-.118.018-.192.022-.262.041-1.009.261-2.014.535-3.027.776a.4.4,0,0,0-.371.448c-.037.932-.1,1.863-.143,2.8-.035.706-.063,1.412-.1,2.168,1.158-.434,2.272-.847,3.38-1.275.07-.027.129-.167.138-.259.1-1.139.2-2.279.292-3.42.034-.417.06-.836.09-1.275Z" transform="translate(-850.295 -136.743)" fill="#fcfdfd"/>
                <path id="Caminho_1027" data-name="Caminho 1027" d="M1057.848-4944.576c.29.076.568.148.849.219l.549.138c.4.1.814.2,1.215.315.081,1.327.15,2.64.221,4.027,0,.061.007.124.01.186l-.019-.007c-.862-.32-1.679-.623-2.5-.938-.089-.961-.169-1.938-.246-2.884-.014-.167-.027-.332-.041-.5-.015-.183-.029-.367-.043-.557m-.541-.646c.034.454.062.849.094,1.243.094,1.153.186,2.305.294,3.457a.411.411,0,0,0,.191.3c1.086.419,2.178.821,3.335,1.251-.092-1.751-.175-3.44-.28-5.128-.006-.095-.172-.228-.286-.261-.608-.172-1.224-.317-1.837-.473-.487-.125-.973-.251-1.511-.39Z" transform="translate(0 -136.095)" fill="#fcfdfd"/>
                <path id="Caminho_1028" data-name="Caminho 1028" d="M1285.865-4885.948l.967.24.908.225.092,3.376.041,1.5c-.609-.211-1.188-.413-1.763-.622-.081-1.473-.154-2.927-.232-4.462l-.013-.257m-.524-.636c.1,1.883.185,3.7.286,5.526a.331.331,0,0,0,.173.239c.834.3,1.673.592,2.582.91-.056-2.061-.109-4.009-.162-5.96l-2.879-.714Z" transform="translate(-221.037 -192.934)" fill="#fcfdfd"/>
                <path id="Caminho_1029" data-name="Caminho 1029" d="M1737.338-4885.584q-.028.541-.056,1.083c-.061,1.182-.124,2.4-.177,3.606,0,.019,0,.035,0,.047-.563.182-1.121.376-1.711.58l-.06.021.127-4.875.818-.2,1.061-.26m.494-.626-2.854.7c-.051,1.946-.1,3.895-.156,5.959.874-.3,1.653-.578,2.44-.833.26-.084.323-.231.334-.489.075-1.7.168-3.4.253-5.1a2.014,2.014,0,0,0-.017-.235Z" transform="translate(-656.726 -193.296)" fill="#fcfdfd"/>
                <path id="Caminho_1030" data-name="Caminho 1030" d="M1928.543-4752.358c-.039.5-.075.976-.113,1.454-.009.1-.016.209-.024.313-.031.407-.064.828-.109,1.238-.788.344-1.574.677-2.4,1.027.014-.329.028-.646.045-.963.038-.685.079-1.414.135-2.141.807-.312,1.616-.612,2.469-.928m-2.458.867h0m3.007-1.594c-1.159.431-2.253.832-3.339,1.253-.085.033-.156.2-.164.317-.058.732-.1,1.465-.14,2.2-.031.564-.052,1.129-.079,1.744,1.115-.473,2.178-.916,3.233-1.38a.439.439,0,0,0,.178-.315c.06-.532.1-1.066.139-1.6.057-.717.111-1.435.172-2.219Z" transform="translate(-841.426 -322.337)" fill="#fcfdfd"/>
                <path id="Caminho_1031" data-name="Caminho 1031" d="M1073.349-4751.57l.259.1c.726.269,1.477.548,2.209.834.068,1.034.124,2.085.178,3.1l-.916-.388-.37-.155c-.369-.154-.75-.314-1.117-.482-.089-1-.167-2.021-.243-3.008m-.511-.7a.572.572,0,0,0-.026.114c.1,1.25.19,2.5.3,3.749a.436.436,0,0,0,.237.272c.506.232,1.023.443,1.537.66l1.6.678a.527.527,0,0,0,.029-.109c-.07-1.3-.135-2.6-.222-3.906-.007-.1-.159-.232-.272-.276-.889-.346-1.784-.676-2.677-1.009-.167-.062-.338-.115-.51-.174Z" transform="translate(-15.029 -323.128)" fill="#fcfdfd"/>
                <path id="Caminho_1032" data-name="Caminho 1032" d="M1190.188-4910.48c.475.124.925.242,1.394.333a.526.526,0,0,1,.108.031.8.8,0,0,1,.019.176c.038,1.16.1,2.335.161,3.473.016.3.033.613.049.922-.512-.19-1-.373-1.486-.565-.062-.989-.116-2-.168-2.969l-.047-.869c-.009-.175-.019-.351-.03-.531m-.528-.645c.025.438.047.82.068,1.2.071,1.3.138,2.609.22,3.913a.406.406,0,0,0,.177.3c.747.3,1.5.574,2.308.875,0-.146,0-.231,0-.316-.081-1.6-.179-3.206-.231-4.809-.013-.4-.115-.592-.524-.673-.664-.13-1.316-.32-2.016-.5Z" transform="translate(-128.292 -169.146)" fill="#fcfdfd"/>
                <path id="Caminho_1033" data-name="Caminho 1033" d="M1840.751-4910.628c-.007.1-.013.2-.019.305q-.031.557-.06,1.112c-.052.97-.1,1.974-.166,2.959-.491.2-1,.383-1.488.564,0-.055.005-.11.008-.166.031-.67.061-1.3.094-1.948.014-.27.03-.545.045-.812.031-.536.063-1.091.079-1.641.5-.113.99-.241,1.506-.375m-1.5.3h0m2.037-.945c-.755.193-1.456.388-2.166.546a.391.391,0,0,0-.368.444c-.023.82-.083,1.64-.124,2.46-.047.929-.09,1.858-.136,2.817a1.346,1.346,0,0,0,.206-.04c.7-.258,1.4-.511,2.089-.79a.438.438,0,0,0,.2-.323c.086-1.378.155-2.758.23-4.137.017-.307.041-.614.065-.979Z" transform="translate(-757.217 -169.001)" fill="#fcfdfd"/>
                <path id="Caminho_1034" data-name="Caminho 1034" d="M1623.2-4638.938c-.009.363-.019.733-.029,1.091-.021.772-.043,1.569-.057,2.357-.61.06-1.222.136-1.815.209l-.172.021v-3.447l.611-.068,1.462-.164m.492-.549-3.056.342v4.44c.864-.1,1.735-.221,2.61-.3.277-.027.352-.132.357-.392.021-1.181.058-2.361.088-3.542,0-.179,0-.359,0-.543Z" transform="translate(-546.047 -432.449)" fill="#fcfdfd"/>
                <path id="Caminho_1035" data-name="Caminho 1035" d="M1401.942-4638.684l.13.013c.527.06,1.072.12,1.613.184.079.009.169.02.24.031,0,.074.005.171.007.261v0q.017.964.035,1.928.012.628.023,1.255l-.2-.022c-.565-.06-1.1-.116-1.635-.19-.055-.007-.138-.019-.207-.031,0-.068,0-.152,0-.228,0-.874,0-1.73,0-2.637,0-.189,0-.379,0-.569m-.491-.549c0,1.275,0,2.516,0,3.757,0,.637.006.656.634.743.783.108,1.57.18,2.4.272,0-.164,0-.273,0-.381q-.03-1.678-.061-3.357c-.012-.691-.011-.7-.68-.775-.775-.092-1.551-.176-2.292-.259Z" transform="translate(-333.583 -432.695)" fill="#fcfdfd"/>
                <path id="Caminho_1036" data-name="Caminho 1036" d="M1515.313-4626.859v3.448h-1.884v-3.448h1.884m.491-.491h-2.866v4.429h2.866v-4.429Z" transform="translate(-441.651 -444.214)" fill="#fcfdfd"/>
                <path id="Caminho_1037" data-name="Caminho 1037" d="M1296.905-4668.69l.018,0c.224.068.452.135.672.2.328.1.668.2,1,.3.042,1.08.073,2.145.105,3.269,0,.059,0,.118.005.176a8.845,8.845,0,0,1-1.552-.583l-.059-.027c-.065-1.1-.124-2.191-.187-3.341m-.53-.7c.079,1.448.154,2.839.236,4.229a.266.266,0,0,0,.124.194,7.808,7.808,0,0,0,2.476.813c-.042-1.44-.079-2.845-.136-4.248,0-.081-.16-.191-.266-.225-.578-.185-1.163-.347-1.743-.523-.219-.066-.433-.15-.691-.24Z" transform="translate(-231.732 -403.459)" fill="#fcfdfd"/>
                <path id="Caminho_1038" data-name="Caminho 1038" d="M1734.731-4666.578q-.011.168-.021.332c-.052.813-.107,1.655-.1,2.495a.866.866,0,0,1-.106.5c-.025.033-.1.135-.407.206a2.973,2.973,0,0,0-.528.188c-.107.045-.208.089-.3.117s-.177.05-.268.071v-.1c0-.537,0-1.044,0-1.558.007-.638.022-1.22.046-1.775.556-.164,1.109-.317,1.687-.477m-1.682.366h0m2.212-1.022c-.857.238-1.7.466-2.542.716-.077.023-.156.183-.161.283-.029.634-.045,1.269-.051,1.9-.008.744,0,1.488,0,2.274.3-.069.6-.124.9-.212.272-.082.525-.235.8-.3a1.033,1.033,0,0,0,.894-1.189c-.006-1.166.1-2.333.163-3.479Z" transform="translate(-654.478 -405.554)" fill="#fcfdfd"/>
                <path id="Caminho_1039" data-name="Caminho 1039" d="M1831.413-4704.467c-.02.4-.04.784-.058,1.17-.007.154-.015.308-.023.462-.025.5-.051,1.012-.065,1.52,0,.013,0,.023,0,.031s-.023.012-.043.02c-.49.2-.972.4-1.48.615,0-.016,0-.033,0-.049.058-1.1.113-2.151.174-3.2.489-.189.981-.373,1.495-.563m-1.489.472h0m2.017-1.191c-.829.309-1.606.592-2.376.894-.07.028-.126.174-.132.269-.079,1.35-.148,2.7-.224,4.132.78-.328,1.482-.633,2.193-.917a.484.484,0,0,0,.354-.492c.019-.658.056-1.316.088-1.973.03-.619.062-1.236.1-1.914Z" transform="translate(-748.218 -368.766)" fill="#fcfdfd"/>
                <path id="Caminho_1040" data-name="Caminho 1040" d="M1201.407-4704.561c.514.191,1,.376,1.49.564.065,1.063.123,2.119.184,3.233v.02c-.519-.219-1.016-.429-1.51-.644-.057-1.03-.108-2.051-.162-3.129,0-.015,0-.029,0-.044m-.528-.721c.07,1.389.135,2.706.208,4.021,0,.087.053.219.117.248.779.342,1.565.669,2.41,1.026-.079-1.438-.152-2.8-.236-4.17a.332.332,0,0,0-.163-.243c-.749-.294-1.505-.572-2.337-.883Z" transform="translate(-139.167 -368.674)" fill="#fcfdfd"/>
                <path id="Caminho_1041" data-name="Caminho 1041" d="M1921.262-4587.559c-.06.73-.117,1.428-.18,2.122-.746.322-1.49.637-2.272.967.015-.343.03-.673.045-1,0-.079.007-.157.011-.235.013-.292.025-.569.05-.846.764-.334,1.535-.66,2.346-1m-2.338.947h0m0,.007h0m2.9-1.722c-1.139.482-2.189.922-3.231,1.379a.406.406,0,0,0-.155.3c-.037.383-.049.768-.067,1.153-.027.575-.052,1.151-.08,1.782,1.081-.457,2.108-.889,3.131-1.331a.312.312,0,0,0,.144-.223c.09-.988.169-1.978.258-3.058Z" transform="translate(-834.559 -482.039)" fill="#fcfdfd"/>
                <path id="Caminho_1042" data-name="Caminho 1042" d="M1086.248-4587.511l.939.4.417.174c.328.136.666.275.989.425.044.687.074,1.369.106,2.087-.746-.312-1.514-.634-2.264-.963-.069-.691-.126-1.388-.186-2.121m-.556-.769c.089,1.075.163,2.051.262,3.026a.467.467,0,0,0,.253.3c.908.4,1.824.779,2.739,1.163a2.541,2.541,0,0,0,.275.078c-.046-1.009-.083-1.977-.146-2.943a.474.474,0,0,0-.261-.3c-.472-.219-.957-.41-1.437-.613l-1.686-.714Z" transform="translate(-27.514 -482.086)" fill="#fcfdfd"/>
                <path id="Caminho_1043" data-name="Caminho 1043" d="M1406.189-4465.043l1.958.208v2.2l-.862-.091-1.1-.116v-2.2m-.491-.547v3.191l2.94.312v-3.188l-2.94-.314Z" transform="translate(-337.701 -601.01)" fill="#fcfdfd"/>
                <path id="Caminho_1044" data-name="Caminho 1044" d="M1619.977-4465.134v2.2l-1.974.216v-2.206l1.974-.212m.491-.546-2.956.317v3.194l2.956-.323v-3.188Z" transform="translate(-543.015 -600.922)" fill="#fcfdfd"/>
                <path id="Caminho_1045" data-name="Caminho 1045" d="M1516.761-4454.239h0m1.672.491v.12c0,.716,0,1.4-.008,2.084-.277.006-.555.008-.83.008s-.551,0-.826-.008c-.01-.687-.009-1.367-.008-2.083v-.121h1.672m.49-.491h-2.653c0,1-.006,1.98.011,2.96,0,.079.161.218.25.221.354.008.709.014,1.063.014s.709-.005,1.064-.014c.09,0,.252-.141.253-.218.017-.98.011-1.961.011-2.962Z" transform="translate(-444.879 -612.013)" fill="#fcfdfd"/>
                <path id="Caminho_1046" data-name="Caminho 1046" d="M1731.249-4492.14c-.035.55-.071,1.119-.065,1.688a.749.749,0,0,1-.081.417c-.019.028-.078.111-.33.173-.369.089-.729.206-1.079.32v-2.117l1.555-.481m.532-.679-2.578.8v3.156c.561-.176,1.116-.38,1.687-.52a.929.929,0,0,0,.785-1.071c-.008-.789.067-1.579.106-2.362Z" transform="translate(-651.279 -574.617)" fill="#fcfdfd"/>
                <path id="Caminho_1047" data-name="Caminho 1047" d="M1306.262-4491.973l.046.014,1.515.462v2.138c-.475-.169-.96-.342-1.436-.52-.047-.7-.086-1.405-.125-2.095m-.53-.675c.056.989.107,1.97.174,2.95a.33.33,0,0,0,.18.232c.735.276,1.477.535,2.227.8v-3.2l-2.581-.788Z" transform="translate(-240.802 -574.783)" fill="#fcfdfd"/>
                <path id="Caminho_1048" data-name="Caminho 1048" d="M1210.416-4534.934c.512.216,1,.424,1.493.636.044.689.083,1.379.124,2.1-.52-.221-1.017-.433-1.509-.651-.04-.686-.073-1.368-.108-2.084m-.528-.757c.051,1.027.094,1.994.152,2.961a.356.356,0,0,0,.144.266c.767.343,1.542.667,2.384,1.027-.061-1.076-.115-2.078-.18-3.079a.273.273,0,0,0-.154-.177c-.761-.33-1.526-.651-2.345-1Z" transform="translate(-147.898 -533.061)" fill="#fcfdfd"/>
                <path id="Caminho_1049" data-name="Caminho 1049" d="M1824.428-4534.034c0,.216,0,.426-.006.637-.013.47-.04.947-.079,1.421-.488.218-.981.429-1.5.646.037-.577.071-1.124.059-1.685a.645.645,0,0,1,.079-.393.707.707,0,0,1,.363-.2,9.723,9.723,0,0,0,1.082-.425m.487-.752c-.571.241-1.126.526-1.714.708a.956.956,0,0,0-.788,1.075c.017.789-.063,1.579-.1,2.435.834-.355,1.611-.676,2.375-1.023.085-.039.138-.22.148-.339.04-.483.068-.968.081-1.452.013-.462,0-.924,0-1.4Z" transform="translate(-741.528 -533.937)" fill="#fcfdfd"/>
                <path id="Caminho_1050" data-name="Caminho 1050" d="M1097.19-4450.11l1.4.591c1.4.586,2.839,1.192,4.242,1.818a13.963,13.963,0,0,0,3.735.986,29.339,29.339,0,0,0,4.207.3c.486,0,.984-.011,1.48-.033a23.249,23.249,0,0,0,4.578-.551,12.4,12.4,0,0,0,1.779-.619c1.6-.665,3.222-1.353,4.79-2.02l1.086-.461c-.056.625-.122,1.333-.155,1.688,0,0,0,.009-.013.02l-8.941,7.138c-.614.174-1.186.311-1.747.416a3.507,3.507,0,0,1-.026-.375c0-.9,0-1.823,0-2.712q0-.417,0-.835v-.424a.491.491,0,0,0-.491-.49h-4.543a.491.491,0,0,0-.491.49v.432q0,.464,0,.929c0,.87,0,1.771,0,2.655a2.24,2.24,0,0,1-.024.321c-.11-.021-.218-.039-.324-.056-.169-.028-.33-.055-.486-.091s-.29-.072-.442-.115l-.115-.031-9.369-7.48c-.046-.48-.088-.966-.128-1.437,0-.016,0-.033,0-.049m27.858-.75h0Zm0,0c-.167.064-.27.1-.371.145-2.083.883-4.163,1.773-6.251,2.641a11.874,11.874,0,0,1-1.708.593,23.014,23.014,0,0,1-4.482.538q-.729.033-1.458.033a28.741,28.741,0,0,1-4.136-.291,13.469,13.469,0,0,1-3.606-.948c-1.984-.885-4-1.708-6-2.557-.117-.05-.237-.091-.4-.151.014.212.022.36.035.507.057.661.111,1.321.177,1.981l9.611,7.673c.225.06.447.127.675.178.3.068.611.106.915.167a.609.609,0,0,0,.12.014c.206,0,.313-.133.354-.351a2.718,2.718,0,0,0,.044-.482c0-1.195,0-2.39,0-3.585v-.432h4.543v.424q0,1.774,0,3.547a4.014,4.014,0,0,0,.031.446c.032.278.143.432.4.432a.861.861,0,0,0,.153-.016c.65-.121,1.285-.276,1.911-.458l9.017-7.2a.513.513,0,0,0,.2-.357c.049-.516.171-1.825.224-2.491Z" transform="translate(-38.128 -615.289)" fill="#fcfdfd"/>
            </g>
        </svg>
    )
}

export default Sindicatos;