import styled from 'styled-components';

export const Container = styled.div`
    width: 250px;
    height: 330px;
    background-color: #3192D2;
    border-radius: 30px 0px 30px 30px;
    justify-content: center;
    text-align: center;
`;

export const InfoUser = styled.div`
    display: grid;
`;

export const Center = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

export const Nome = styled.text`
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF;
`;

export const Cargo = styled.text`
    font-size: 8px;
    color: #FFFFFF;
`;

export const Sombra = styled.div`
    width: 100%;
    height: 120px;
    border-radius: 30px;
    background-color: #0664A2;
    color: #FFFFFF;
    margin-top: 20px;
`;

export const Text = styled.div`
    display: grid;
    justify-content: start;
`;

export const Title = styled.text`
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase; 
    color: #FFFFFF;
`;

export const Deslogar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
`;

export const TextDeslogar = styled.text`
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
`;