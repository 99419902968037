import React, { useState, useEffect} from 'react';
import logo from 'assets/img/logo/logo_login.png';
import maiorIcon from 'assets/img/icones/maior.svg';
import { Button, Form, Card, Col, Row, Label } from 'reactstrap';
import * as S from './styled';
import Axios from '../../utils/Axios';
import Footer from '../../components/Footer';

const Login = () => {
  const [confirmPassword, setConfirmPassword] = useState();
  const [password, setPassword] = useState();
  const [erro, setErro] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [cpf, setCpf] = useState('');
  const [mensagem, setMensagem] = useState("");

  const handleFormSubmit = async () => {
    if(password !== confirmPassword){
      setMensagem("Suas senhas não conferem");
      setErro(true);
      setTimeout(() => { setErro(false); }, 5000);
    }else{
      if(password.length >= 6){
        await Axios.post(`/senha/reset`,{
            password: password,
            codigo: codigo,
            cpf: cpf,
          }, {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((res) => {
          if(res.status === 200) {
            window.location.href = `${process.env.PUBLIC_URL}/login`;
          }
        }).catch((res) => {
          if(res.message === "Request failed with status code 401") {
            setErro(!erro);
          }
        })
      }else{
        setMensagem("Suas senha deve conter pelo menos 6 digítos!");
        setErro(true);
        setTimeout(() => { setErro(false); }, 5000);
      }
    }
  };

  useEffect( () => {
    let codigo = localStorage.getItem('codigo');
    let cpf = localStorage.getItem('cpf');
    setCodigo(codigo);
    setCpf(cpf);
    // if(cpf === null) {
    //   window.location.href = `${process.env.PUBLIC_URL}/esqueci-senha`;
    // }
    localStorage.removeItem('codigo');
    localStorage.removeItem('cpf');
  }, []);

  return (
    <Row
      style={{
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#3192D2',
        marginLeft: '0px'
    }}>
      <Col md={6} lg={6}>
        <Card body style={{ backgroundColor: '#3192D2', padding: '40px 50px', border: '0px' }}>
          <Form>
            <div className="text-center pb-4">
              <img
                src={logo}
                className="rounded"
                style={{ width: 320, height: 90 }}
                alt="logo"
              />
            </div>
            <S.DivInput>
              <Label
                for="exampleEmail2"
              >
                <S.CustomCadeado style={{ width: "40px", height: "40px", marginTop: "5px", marginRight: "-15px" }} />
              </Label>
              <Col>
                <S.CustomInput
                  id="password"
                  type="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="Nova senha"
                  style={{
                    borderBottomColor: 'white',
                    borderBottomWidth: 1,
                    width: '100%'
                  }}
                />
              </Col>
            </S.DivInput>
            <S.DivInput>
              <Label
                for="exampleEmail2"
              >
                <S.CustomCadeado style={{ width: "40px", height: "40px", marginTop: "5px", marginRight: "-15px" }} />
              </Label>
              <Col>
                <S.CustomInput
                  id="confirmPassword"
                  type="password"
                  name="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  placeholder="Confirme nova senha"
                  style={{
                    borderBottomColor: 'white',
                    borderBottomWidth: 1,
                    width: '100%'
                  }}
                />
              </Col>
            </S.DivInput>
            {erro && 
              <S.CustomFormText color="red">
                {mensagem}
              </S.CustomFormText>
            }
            <Button size="lg" block color="#FFFFFF" style={{ border: '1px solid #FFFFFF', borderRadius: '20px', marginTop: '15px' }} onClick={() => handleFormSubmit()}>
              <img src={maiorIcon} alt="" style={{ marginRight: '10px' }} />
              <S.TextLogin>ALTERAR SENHA</S.TextLogin>
            </Button>
          </Form>
        </Card>
      </Col>
      <Footer />
    </Row>
  );
}

export default Login;