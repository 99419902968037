import React from 'react';

function Juridico(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31.271" height="30.067" viewBox="0 0 31.271 30.067"  fill="none" {...props}>
            <path id="Caminho_821" data-name="Caminho 821" d="M-6298.48-69.188h-10.8a3.43,3.43,0,0,1-2.441-1.011l-2.432-2.432a3.99,3.99,0,0,0-2.839-1.176h-4.729a4.019,4.019,0,0,0-4.014,4.014v22.038a4.019,4.019,0,0,0,4.014,4.014h23.242a4.019,4.019,0,0,0,4.015-4.014V-65.174A4.019,4.019,0,0,0-6298.48-69.188Zm-23.242-4.056h4.729a3.43,3.43,0,0,1,2.441,1.011l2.432,2.432a3.991,3.991,0,0,0,2.839,1.176h10.8a3.456,3.456,0,0,1,3.452,3.452v1.45h-30.146v-6.069A3.456,3.456,0,0,1-6321.722-73.244ZM-6298.48-44.3h-23.242a3.456,3.456,0,0,1-3.452-3.452V-63.161h30.146v15.406A3.456,3.456,0,0,1-6298.48-44.3Z" transform="translate(6325.736 73.807)" fill="#fcfdfd"/>
        </svg>
    )
}

export default Juridico;