import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Content } from 'components/Layout';
import { Navbar } from 'reactstrap';

export const Center = styled(Link)`
    display: flex;
    justify-content: center;
`;

export const Sombra = styled.div`
    width: 100%;
    height: 35%;
    border-radius: 30px;
    background-color: #0664A2;
`;

export const Text = styled.div`
    display: grid;
    justify-content: start;
`;

export const Title = styled.text`
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase; 
`;

export const SubTitle = styled.text`
    font-size: 8px;
`;

export const Sombra2 = styled.div`
    width: 100%;
    height: 20%;
    border-radius: 30px;
    background-color: #0664A2;
    bottom: 0;
    position: absolute;
    margin-bottom: 155px;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const Nome = styled.text`
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF;
    margin-right: 5px;
`;

export const Cargo = styled.text`
    font-size: 8px;
    color: #FFFFFF;
    margin-right: 5px;
`;

export const InfoUser = styled.div`
    display: grid;
    text-align: right;

    @media (max-width: 1024px) {
        display: none;
    }
`;

export const DivDown = styled.div`
    @media (max-width: 1024px) {
        display: none;
    }
`;

export const DivDownMobile = styled.div`
    margin-top: -10px;
    margin-left: 10px;
    @media (min-width: 1025px) {
        display: none;
    }
`;

export const CustomContent = styled(Content)`
    overflow: hidden;
`;

export const CustomNavbar = styled(Navbar)`
    padding: 0px 178px;

    @media (max-width: 1024px) {
        padding: 0px 0px;
    }
`;