import React from 'react';

function Agenda(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="150.688" height="129.129" viewBox="0 0 150.688 129.129" fill="none" {...props}>
        <path id="Caminho_912" data-name="Caminho 912" d="M-5948.571-4323.25v-.006h-19.85v-9.864a6.194,6.194,0,0,0-6.187-6.187,6.194,6.194,0,0,0-6.187,6.188v9.864h-41.49v-9.864a6.2,6.2,0,0,0-6.187-6.187,6.2,6.2,0,0,0-6.187,6.188v9.864h-19.419a22.832,22.832,0,0,0-22.806,22.806v67.466a22.831,22.831,0,0,0,22.806,22.806H-5949a22.831,22.831,0,0,0,22.805-22.806v-67.466A22.831,22.831,0,0,0-5948.571-4323.25Zm-29.749-9.87a3.716,3.716,0,0,1,3.712-3.712,3.716,3.716,0,0,1,3.712,3.712v22.273a3.716,3.716,0,0,1-3.712,3.711,3.715,3.715,0,0,1-3.712-3.711Zm-53.865,0a3.716,3.716,0,0,1,3.712-3.712,3.716,3.716,0,0,1,3.712,3.712v22.273a3.715,3.715,0,0,1-3.712,3.711h0a3.716,3.716,0,0,1-3.712-3.711Zm-21.894,12.339h19.419v9.934a6.194,6.194,0,0,0,6.187,6.187,6.194,6.194,0,0,0,6.188-6.187v-9.934h41.49v9.934a6.194,6.194,0,0,0,6.187,6.187,6.194,6.194,0,0,0,6.187-6.187v-9.934H-5949a20.353,20.353,0,0,1,20.33,20.331v3.943H-6074.41v-3.943A20.353,20.353,0,0,1-6054.079-4320.78ZM-5949-4212.653h-105.078a20.353,20.353,0,0,1-20.331-20.331v-62.8h145.739v62.8A20.353,20.353,0,0,1-5949-4212.653Z" transform="translate(6076.885 4339.307)" fill="#fcfdfd"/>
      </svg>
    )
}

export default Agenda;