import React, {useState, useEffect} from 'react';
import * as S from './styled';
import logoAlternativa from '../../assets/img/logo/alternativa_digital.png';
import Axios from '../../utils/Axios';

const Footer = () => {
    const [dados, setDados] = useState([]);

    useEffect( () => {
        let token = localStorage.getItem('token');
        Axios.defaults.headers.Authorization = `Bearer ${token}`;
        Axios.get(`/versao`).then((response) => {
          setDados(response.data);
        })
    }, []);

    return (
        <S.Footer>
            <S.Assinatura>
                <img src={logoAlternativa} alt="" width={"104px"} height={"29px"} />
            </S.Assinatura>
            {dados !== undefined && dados.map((item) => (
                <S.Versao key={item.id}>
                    {item.nome} - {item.versao}
                </S.Versao>
            ))}
        </S.Footer>
    );
};

export default Footer;