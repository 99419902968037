import React, {useState} from 'react';
import logo from 'assets/img/logo/logo_login.png';
import maiorIcon from 'assets/img/icones/maior.svg';
import { Button, Form, Card, Col, Row, Label } from 'reactstrap';
import * as S from './styled';
import Axios from '../../utils/Axios';
import Footer from '../../components/Footer';

const Login = () => {
  const [cpf, setCPF] = useState();
  const [password, setPassword] = useState();
  const [erro, setErro] = useState(false);
  // const [viewPass, setViewPass] = useState(false);

  const handleFormSubmit = async () => {
    await Axios.post(`/auth/login`,{
        cpf: cpf,
        password: password,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      if(res.status === 200) {
        localStorage.setItem('token', res.data.token);
        window.location.href = `${process.env.PUBLIC_URL}/home`;
      }
    }).catch((res) => {
      if(res.message === "Request failed with status code 401") {
        setErro(true);
        setTimeout(() => { setErro(false); }, 5000);
      }
    })
  };

  return (
    <Row
      style={{
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#3192D2',
        marginLeft: '0px'
    }}>
      <Col md={6} lg={6}>
        <Card body style={{ backgroundColor: '#3192D2', padding: '40px 50px', border: '0px' }}>
          <Form>
            <div className="text-center pb-4">
              <img
                src={logo}
                className="rounded"
                style={{ width: 320, height: 90 }}
                alt="logo"
              />
            </div>
            <S.DivInput>
              <Label
                for="exampleEmail2"
              >
                <S.CustomCPF style={{ marginTop: "15px" }} />
              </Label>
              <S.CustomInput
                id="cpf"
                type="text"
                name="cpf"
                onChange={(e) => setCPF(e.target.value)}
                value={cpf}
                placeholder="Digite CPF"
                mask="999.999.999-99"
                style={{
                  borderBottomColor: 'white',
                  borderBottomWidth: 1,
                  width: '100%'
                }}
              />
            </S.DivInput>
            <S.DivInput>
              <Label
                for="exampleEmail2"
              >
                <S.CustomCadeado style={{ width: "40px", height: "40px", marginTop: "5px", marginRight: "-15px" }} />
              </Label>
              <Col>
                <S.CustomInput
                  id="password"
                  // type={viewPass ? "text" : "password"}
                  type="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="Digite Sua Senha"
                  style={{
                    borderBottomColor: 'white',
                    borderBottomWidth: 1,
                    width: '100%'
                  }}
                />
              </Col>
            </S.DivInput>
            {/* <S.DivInput onClick={()=> setViewPass(!viewPass)} style={{ curosr: "pointer" }}>SENHA</S.DivInput> */}
            {erro && 
              <S.CustomFormText color="white">
                Usuário e/ou senha inválidos.
              </S.CustomFormText>
            }
            <Button size="lg" block color="#FFFFFF" style={{ border: '1px solid #FFFFFF', borderRadius: '20px', marginTop: '15px' }} onClick={() => handleFormSubmit()}>
              <img src={maiorIcon} alt="" style={{ marginRight: '10px' }} />
              <S.TextLogin>ENTRAR</S.TextLogin>
            </Button>
            <S.EsqueciSenha>
              <S.CustomCadeado style={{ width: "20px", height: "20px" }} /><S.CustomLink to="/esqueci-senha">Esqueci minha senha</S.CustomLink>
            </S.EsqueciSenha>
          </Form>
        </Card>
      </Col>
      <Footer />
    </Row>
  );
}

export default Login;