import React from 'react';

function Cadeado(props) {
    return (
        <svg id="Grupo_656" data-name="Grupo 656" xmlns="http://www.w3.org/2000/svg" width="13.927" height="17.612" viewBox="0 0 13.927 17.612" fill="none" {...props}>
            <g id="Grupo_658" data-name="Grupo 658">
                <g id="Grupo_657" data-name="Grupo 657">
                    <path id="Caminho_324" data-name="Caminho 324" d="M234.059,124.561H233.8v-.853a5.29,5.29,0,1,0-10.581,0v.853h-.255a1.418,1.418,0,0,0-1.418,1.418v8.632a1.419,1.419,0,0,0,1.418,1.418h11.091a1.418,1.418,0,0,0,1.418-1.418v-8.632A1.418,1.418,0,0,0,234.059,124.561Zm-10.439-.853a4.894,4.894,0,0,1,9.787,0v.853H223.62Zm11.461,10.583a1.217,1.217,0,0,1-1.2,1.234h-10.7a1.217,1.217,0,0,1-1.2-1.234v-8.027a1.217,1.217,0,0,1,1.2-1.234h10.7a1.217,1.217,0,0,1,1.2,1.234Z" transform="translate(-221.55 -118.417)" fill="#fff"/>
                </g>
                <path id="Caminho_325" data-name="Caminho 325" d="M264.6,167.644a.705.705,0,0,0-.705.7V170a.705.705,0,0,0,1.41,0v-1.651A.705.705,0,0,0,264.6,167.644Z" transform="translate(-257.637 -160.368)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default Cadeado;