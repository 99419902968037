import React, {useState} from 'react';
import logo from 'assets/img/logo/logo_login.png';
import maiorIcon from 'assets/img/icones/maior.svg';
import { Button, Form, Card, Col, Row, Label } from 'reactstrap';
import * as S from './styled';
import Axios from '../../utils/Axios';
import Footer from '../../components/Footer';

const Login = () => {
  const [cpf, setCPF] = useState();
  const [erro, setErro] = useState(false);
  const [mensagem, setMensagem] = useState("");

  const handleFormSubmit = async () => {
    await Axios.post(`/senha/email`,{
      cpf: cpf,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      if(res.status === 200) {
        localStorage.setItem('email', res.data.email);
        localStorage.setItem('cpf', cpf);
        window.location.href = `${process.env.PUBLIC_URL}/recuperar-senha`;
      }
    }).catch((res) => {
      if(res.response.data.status === 500){
        setMensagem("Houve alguma dificuldade, tente novamente mais tarde");
        setErro(true);
        setTimeout(() => { setErro(false); }, 5000);
      } else {
        const mensagem = res.response.data.message
        setMensagem(mensagem);
        setErro(true);
        setTimeout(() => { setErro(false); }, 5000);
      }
    })
  };

  return (
    <Row
      style={{
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#3192D2',
        marginLeft: '0px'
    }}>
      <Col md={6} lg={6}>
        <Card body style={{ backgroundColor: '#3192D2', padding: '40px 50px', border: '0px' }}>
          <Form>
            <div className="text-center pb-4">
              <img
                src={logo}
                className="rounded"
                style={{ width: 320, height: 90 }}
                alt="logo"
              />
            </div>
            <S.DivText>
              <S.TextLogin>Esqueci minha senha</S.TextLogin>
            </S.DivText>
            <S.DivInput>
              <Label
                for="exampleEmail2"
              >
                <S.CustomCPF style={{ marginTop: "15px" }} />
              </Label>
              <S.CustomInput
                id="cpf"
                type="text"
                name="cpf"
                onChange={(e) => setCPF(e.target.value)}
                value={cpf}
                placeholder="Digite CPF"
                mask="999.999.999-99"
                style={{
                  borderBottomColor: 'white',
                  borderBottomWidth: 1,
                  width: '100%'
                }}
              />
            </S.DivInput>
            {erro && 
              <S.CustomFormText color="white">
                {mensagem}
              </S.CustomFormText>
            }
            <Button size="lg" block color="#FFFFFF" style={{ border: '1px solid #FFFFFF', borderRadius: '20px', marginTop: '20px' }} onClick={() => handleFormSubmit()}>
              <img src={maiorIcon} alt="" style={{ marginRight: '10px' }} />
              <S.TextLogin>RECUPERAR SENHA</S.TextLogin>
            </Button>
          </Form>
        </Card>
      </Col>
      <Footer />
    </Row>
  );
}

export default Login;