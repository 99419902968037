import React from 'react';
import * as S from './styled';
import Avatar from '../Avatar';
import perfil from '../../assets/img/icones/perfil.svg';
import config from '../../assets/img/icones/config.svg';
import ajuda from '../../assets/img/icones/ajuda.svg';
import {
    Nav,
    NavItem,
    NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { NavLink } from 'react-router-dom';
import DeslogarIcon from '../../assets/img/icones/deslogar.svg';

const NewCard = (props) => {
    const navItems2 = [
        { to: '/perfil', name: 'meu perfil', exact: true, Icon: perfil },
        { to: '/config', name: 'configuração de conta', exact: true, Icon: config },
        { to: '/ajuda', name: 'ajuda', exact: true, Icon: ajuda },
    ];

    const bem = bn.create('sidebar'); 

    const handleFormSubmit = async () => {
        localStorage.removeItem('token');
        window.location.href = `${process.env.PUBLIC_URL}/login`;
      };
    
    return (
        <S.Container>
            <S.InfoUser>
                <S.Center>
                    <Avatar src={props.icone} size={96} />
                </S.Center>
                <S.Nome>{props.nome}</S.Nome>
                <S.Cargo>{props.cargo}</S.Cargo>
            </S.InfoUser>
            <S.Sombra>
                <Nav vertical style={{ padding: "10px 0px" }}>
                    {navItems2.map(({ to, name, exact, Icon }, index) => (
                        <NavItem key={index} className={bem.e('nav-item')} style={{ padding: "0px 20px" }}>
                        <BSNavLink
                            id={`navItem-${name}-${index}`}
                            tag={NavLink}
                            to={to}
                            activeClassName="active"
                            exact={exact}
                        >
                            <img src={Icon} alt="" style={{ marginRight: '10px' }} />
                            <S.Text>
                                <S.Title>{name}</S.Title>
                            </S.Text>
                        </BSNavLink>
                        </NavItem>
                    ))}
                </Nav>
            </S.Sombra>
            <S.Deslogar onClick={() => handleFormSubmit()}>
                <img src={DeslogarIcon} alt="" style={{ marginRight: '10px' }} />
                <S.TextDeslogar>SAIR</S.TextDeslogar>
            </S.Deslogar>
            {/* <Down style={{ width:"26px", height:"12px", marginLeft: "5px"}} /> */}
        </S.Container>
        
    )
}

export default NewCard;