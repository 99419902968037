import React from 'react';

function Eventos(props) {
    return (
        <svg id="Grupo_1543" data-name="Grupo 1543" xmlns="http://www.w3.org/2000/svg" width="31.271" height="26.797" viewBox="0 0 31.271 26.797" fill="none" {...props}>
            <path id="Caminho_913" data-name="Caminho 913" d="M-6053.457-2874.975h-4.119v-2.047a1.286,1.286,0,0,0-1.284-1.284,1.286,1.286,0,0,0-1.284,1.284v2.047h-8.61v-2.047a1.285,1.285,0,0,0-1.284-1.284,1.286,1.286,0,0,0-1.284,1.284v2.047h-4.03a4.738,4.738,0,0,0-4.732,4.732v14a4.738,4.738,0,0,0,4.732,4.733h21.806a4.738,4.738,0,0,0,4.732-4.733v-14A4.738,4.738,0,0,0-6053.457-2874.975Zm-6.173-2.048a.772.772,0,0,1,.771-.771.771.771,0,0,1,.77.771v4.622a.771.771,0,0,1-.77.77.771.771,0,0,1-.771-.77Zm-11.178,0a.772.772,0,0,1,.77-.771.772.772,0,0,1,.771.771v4.622a.772.772,0,0,1-.771.77h0a.771.771,0,0,1-.77-.77Zm-4.543,2.561h4.03v2.062a1.286,1.286,0,0,0,1.284,1.284,1.285,1.285,0,0,0,1.284-1.284v-2.062h8.61v2.062a1.286,1.286,0,0,0,1.284,1.284,1.286,1.286,0,0,0,1.284-1.284v-2.062h4.03a4.223,4.223,0,0,1,4.218,4.219v.818h-30.243v-.818A4.223,4.223,0,0,1-6075.352-2874.463Zm21.806,22.439h-21.806a4.223,4.223,0,0,1-4.218-4.219v-13.031h30.243v13.031A4.223,4.223,0,0,1-6053.545-2852.024Z" transform="translate(6080.084 2878.307)" fill="#fcfdfd"/>
            <path id="Caminho_914" data-name="Caminho 914" d="M-5722.647-2473.22l-7.121,7.121-2.176-2.41a.3.3,0,0,0-.411.075.3.3,0,0,0,.075.411l2.379,2.55a.3.3,0,0,0,.168.052.293.293,0,0,0,.208-.086l7.294-7.295a.3.3,0,0,0,0-.417A.3.3,0,0,0-5722.647-2473.22Z" transform="translate(5742.911 2485.542)" fill="#fcfdfd"/>
        </svg>
    )
}

export default Eventos;