import React, {useState, useEffect} from 'react';
import Avatar from 'components/Avatar';
import NewCard from '../NewCard';
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Col,
} from 'reactstrap';
import {
  MdClearAll,
  MdClear,
} from 'react-icons/md';
import * as S from './styled';
import Axios from '../../utils/Axios';
import bn from 'utils/bemnames';
import logo from '../../assets/img/logo/logo_header.png';
import Down from '../../assets/img/icones/Down';

const Header = () => {
  const [dados, setDados] = useState([]);
  const bem = bn.create('header');
  const [card, setCard] = useState(false);
  const [menu, setMenu] = useState(false);

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/user`)
    .then((response) => {
      setDados(response.data)
    })
    .catch((res) => {
      if(res.response.data.message === "Token has expired"){
        window.location.href = `${process.env.PUBLIC_URL}/login`;
      } 
    })
  }, []);

  const handleSidebarControlButton = event => {
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  return (
    <S.CustomNavbar light expand fixed="top" className={bem.b('bg-blue')} style={{ backgroundColor: '#3192D2', border: '1px solid #3192D2' }}>
      <Col sm={4} style={{ display: "flex", justifyContent: "start" }}>
        <Nav navbar className="mr-2">
          <Button outline color='white' onClick={() => {handleSidebarControlButton();setMenu(!menu)}}>
            {menu ? <MdClear size={25} /> : <MdClearAll size={25} />}
          </Button>
        </Nav>
      </Col>
      <Col sm={4} style={{ display: "flex", justifyContent: "center" }}>
        <S.Center to="/home">
          <img src={logo} alt="" />
        </S.Center>
      </Col>
      <Col sm={4} style={{ display: "flex", justifyContent: "end" }}>
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <S.Container>
              <NavLink id="Popover2">
                <S.InfoUser>
                  <S.Nome>{dados.name}</S.Nome>
                  <S.Cargo>{dados.cargo}</S.Cargo>
                </S.InfoUser>
                <Avatar
                  onClick={() => {setCard(!card);}}
                  className="can-click"
                />
                <S.DivDown>
                  <Down style={{ width:"26px", height:"12px", marginLeft: "5px"}} />
                </S.DivDown>
              </NavLink>
            </S.Container>
            <S.DivDownMobile>
              <Down style={{ width:"26px", height:"12px", marginLeft: "5px"}} />
            </S.DivDownMobile>
            <Popover
              placement="bottom-end"
              hideArrow={true}
              isOpen={card}
              toggle={() => {setCard(!card);}}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250, marginTop: '-10px' }}
            >
              <PopoverBody className="p-0 border-light">
                <NewCard
                  nome={dados.name}
                  cargo={dados.cargo}
                >
                </NewCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Col>
    </S.CustomNavbar>
  );
}

export default Header;