import React from 'react';

function Down(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.743" height="11.294" viewBox="0 0 25.743 11.294" fill="none" {...props}>
            <path id="Caminho_1017" data-name="Caminho 1017" d="M-2308.681-1854.307a.37.37,0,0,0-.262.109l-1.979,1.986c-2.429,2.439-4.941,4.962-7.5,7.493a4.55,4.55,0,0,1-1.628.821,3.161,3.161,0,0,1-.745.121c-.087.007-.176.015-.266.025a3.582,3.582,0,0,1-1.9-.425.443.443,0,0,0-.209-.084,2.421,2.421,0,0,0-.333-.222,1.348,1.348,0,0,1-.274-.188l-.02-.018c-.606-.525-1.186-1.1-1.748-1.667-1.5-1.478-2.979-2.958-4.411-4.39-1.134-1.134-2.3-2.3-3.465-3.454a.37.37,0,0,0-.261-.107h0a.37.37,0,0,0-.26.632c1.168,1.158,2.33,2.319,3.464,3.452,1.433,1.432,2.914,2.913,4.412,4.391.567.567,1.154,1.153,1.776,1.694a2.034,2.034,0,0,0,.414.294,1.338,1.338,0,0,1,.274.188.442.442,0,0,0,.285.127,4.369,4.369,0,0,0,2.276.515h.02l.02,0c.087-.01.175-.017.267-.025a3.774,3.774,0,0,0,.918-.156,5.156,5.156,0,0,0,1.9-.982l.01-.01c2.564-2.535,5.078-5.06,7.51-7.5l1.978-1.986a.369.369,0,0,0-.261-.63Z" transform="translate(2334.053 1854.307)" fill="#fff"/>
        </svg>
    )
}

export default Down;