import React from 'react';

function CPF(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="15" viewBox="0 0 26 15" fill="none" {...props}>
            <g id="ICO_CPF" transform="translate(-277 -382)">
                <g id="Retângulo_2200" data-name="Retângulo 2200" transform="translate(277 382)" fill="none" stroke="#fcfdfd" stroke-width="0.5">
                <rect width="26" height="15" rx="3" stroke="none"/>
                <rect x="0.25" y="0.25" width="25.5" height="14.5" rx="2.75" fill="none"/>
                </g>
                <text id="_._._-_" data-name="***.***.***-**" transform="translate(300 393)" fill="#fcfdfd" font-size="3" font-family="OpenSans-ExtraboldItalic, Open Sans" font-weight="800" font-style="italic"><tspan x="-20.71" y="0">***.***.***-**</tspan></text>
            </g>
        </svg>
    )
}

export default CPF;