import styled from 'styled-components';

export const Assinatura = styled.section`
    display: flex;
    background-color: #3192D2;
    width: 100%;
    height: 60px;
    justify-content: center;
    align-items: center;
`;

export const Versao = styled.section`
    display: flex;
    background-color: #3192D2;
    width: 124px;
    height: 28px;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
    font-size: 16px;
    color: #FFFFFF;
`;

export const Footer = styled.div`
    bottom: 0;
    position: fixed;
    margin-bottom: 30px;
    padding: 0 50px;
`;