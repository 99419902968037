import React from 'react';

function Sindicalizados(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.073" height="30" viewBox="0 0 22.073 30" fill="none" {...props}>
            <g id="Grupo_1395" data-name="Grupo 1395" transform="translate(0)">
                <path id="Caminho_842" data-name="Caminho 842" d="M-5036.166,2495.6a8.1,8.1,0,0,0-5.117-4.177l-.4-.107.383-.148a6.326,6.326,0,0,0,4.018-5.853,6.289,6.289,0,0,0-6.281-6.281,6.29,6.29,0,0,0-6.283,6.281,6.3,6.3,0,0,0,4.154,5.9l.386.139-.394.116a8.153,8.153,0,0,0-5.812,7.771v5.441a.228.228,0,0,0,.229.228h11.062a.227.227,0,0,0,.227-.228.228.228,0,0,0-.227-.228h-10.834v-5.213a7.656,7.656,0,0,1,7.647-7.647,7.615,7.615,0,0,1,6.833,4.208.228.228,0,0,0,.133.114.226.226,0,0,0,.174-.013.229.229,0,0,0,.114-.132A.228.228,0,0,0-5036.166,2495.6Zm-7.393-4.459a5.833,5.833,0,0,1-5.827-5.826,5.833,5.833,0,0,1,5.827-5.826,5.832,5.832,0,0,1,5.825,5.826A5.833,5.833,0,0,1-5043.559,2491.141Z" transform="translate(5058.213 -2474.914)" fill="#fcfdfd"/>
                <path id="Caminho_843" data-name="Caminho 843" d="M-5287.963,2328.039l-.01-.011a.244.244,0,0,0-.155-.159,6.26,6.26,0,0,0-4.432-1.835,6.289,6.289,0,0,0-6.282,6.282,6.3,6.3,0,0,0,4.153,5.9l.386.14-.394.116a8.151,8.151,0,0,0-5.811,7.771v5.441a.227.227,0,0,0,.227.228h4.288a.23.23,0,0,0,.045,0,.22.22,0,0,0,.044,0h0a.228.228,0,0,0,.184-.224.228.228,0,0,0-.228-.229h0v0h-4.1v-5.213a7.657,7.657,0,0,1,6.935-7.614v0a.256.256,0,0,0,.226-.254.255.255,0,0,0-.247-.255v0a5.834,5.834,0,0,1-5.247-5.8,5.832,5.832,0,0,1,5.826-5.826,5.811,5.811,0,0,1,4.155,1.746.24.24,0,0,0,.2.105.243.243,0,0,0,.243-.243.244.244,0,0,0-.007-.057Z" transform="translate(5300.507 -2326.034)" fill="#fcfdfd"/>
            </g>
        </svg>
    )
}

export default Sindicalizados;