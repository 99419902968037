import styled from 'styled-components';
import InputMask from "react-input-mask";
import Cadeado from '../../assets/img/icones/Cadeado';
import { FormText } from 'reactstrap';

export const DivText = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const TextLogin = styled.text`
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 50px;
`;

export const TextLogin2 = styled.text`
    color: #FFFFFF;
    font-size: 18px;
`;

export const EsqueciSenha = styled.section`
    display: flex;
    width: 100%;
    height: 18px;
    gap: 10px;
    justify-content: right;
    align-items: right;
    margin-top: 10px;
    cursor: pointer;
`;

export const Link = styled.text`
    color: #FFFFFF;
    font-size: 15px;
`;

export const TextLabel = styled.text`
    font-size: 15px;
    color: #FFFFFF;
`;

export const DivInput = styled.div`
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    height: 63px;
`;

export const CustomInput = styled(InputMask)`
    width: 200px;
    background: #3192D2;
    color: #FFFFFF;
    height: 60px;
    font-size: 30px;
    border: none;

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
        background: #3192D2;
        color: #FFFFFF;
    }
    
    &::-webkit-input-placeholder {
        color: #FFFFFF;
    }
`;

export const CustomCadeado = styled(Cadeado)`
    & path {
        fill: #FFFFFF;
        color: #FFFFFF;
    }
`;

export const CustomFormText = styled(FormText)`
    width: 100%;
    height: 50px;
    background-color: ${({ color }) => { if (color === 'green') { return '#68A884'; } else { return '#D24451'; }}};
    color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;