import styled from 'styled-components';
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import Cadeado from '../../assets/img/icones/Cadeado';
import { FormText } from 'reactstrap';

export const TextLogin = styled.text`
    color: #FFFFFF;
`;

export const EsqueciSenha = styled.section`
    display: flex;
    width: 100%;
    height: 18px;
    gap: 10px;
    justify-content: right;
    align-items: right;
    margin-top: 10px;
`;

export const TextLabel = styled.text`
    font-size: 15px;
    color: #FFFFFF;
`;

export const CustomInput = styled(InputMask)`
    padding: 0.5em;
    margin: 0.5em;
    background: #3192D2;
    color: #FFFFFF;
    border-style: solid;
    border-bottom-width: 1px #FFFFFF;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
        background: #3192D2;
        color: #FFFFFF;
        border: 1px solid #3192D2;
    }
    
    &::-webkit-input-placeholder {
        color: #FFFFFF;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
      -webkit-transition-delay: 9999s;
    }
`;

export const CustomLink = styled(Link)`
    text-decoration: none;
    color: #FFFFFF;
    font-size: 15px;

    &:hover {
        color: #FFFFFF;
    }
`;

export const DivInput = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CustomCadeado = styled(Cadeado)`
    & path {
        fill: #FFFFFF;
        color: #FFFFFF;
    }
`;

export const CustomFormText = styled(FormText)`
    width: 100%;
    height: 50px;
    background-color: ${({ color }) => { if (color === 'green') { return '#68A884'; } else { return '#D24451'; }}};
    color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;