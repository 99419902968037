import React, { useState, useEffect } from 'react';
import logo from 'assets/img/logo/logo_login.png';
import imgButton from 'assets/img/icones/maior.svg';
import { Button, Form, FormGroup, Card, Col, Row } from 'reactstrap';
import * as S from './styled';
import Axios from '../../utils/Axios';
import Footer from '../../components/Footer';

const RecuperarSenha = () => {
  const [cod, setCod] = useState();
  const [erro, setErro] = useState(false);
  const [erro2, setErro2] = useState(false);
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [mensagem, setMensagem] = useState("");
  const [colorAlert, setColorAlert] = useState("");

  const handleFormSubmit = async () => {
    await Axios.post(`/senha/email`,{
      cpf: cpf,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      if(res.status === 200) {
        setMensagem("Código reenviado com sucesso!");
        setErro2(true);
        setTimeout(() => { setErro2(false); }, 5000);
      }
    }).catch((res) => {
      if(res.response.data.status === 500){
        setMensagem("Houve alguma dificuldade, tente novamente mais tarde");
        setErro2(true);
        setTimeout(() => { setErro2(false); }, 5000);
      }else {
        const mensagem = res.response.data.message
        setMensagem(mensagem);
        setErro2(true);
        setTimeout(() => { setErro2(false); }, 5000);
      }
    })
  };

  const handleValidSubmit = async () => {
    await Axios.post(`/senha/validation`,{
      cpf: cpf,
      codigo: cod,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      if(res.status === 200) {
        localStorage.setItem('cpf', cpf);
        localStorage.setItem('codigo', cod);
        window.location.href = `${process.env.PUBLIC_URL}/nova-senha`;
      }
    }).catch((res) => {
      setColorAlert("red");
      if(res.response.data.status === 500){
        setMensagem("Houve alguma dificuldade, tente novamente mais tarde");
        setErro(true);
        setTimeout(() => { setErro(false); }, 5000);
        window.location.href = `${process.env.PUBLIC_URL}/esqueci-senha`;
      } else {
        const mensagem = res.response.data.message
        setMensagem(mensagem);
        setErro(true);
        setTimeout(() => { setErro(false); }, 5000);
      }
    })
  };

  useEffect( () => {
    let email = localStorage.getItem('email');
    let cpf = localStorage.getItem('cpf');
    setEmail(email);
    setCpf(cpf);
    if(email === null) {
      window.location.href = `${process.env.PUBLIC_URL}/esqueci-senha`;
    }
    localStorage.removeItem('email');
    localStorage.removeItem('cpf');
  }, []);

  return (
    <Row style={{
      height: '100vh',
      width: '100vw',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#3192D2',
      marginLeft: '0px'
    }}>
      <Col md={6} lg={6}>
        <Card body style={{ backgroundColor: '#3192D2', padding: '40px 50px', border: '0px' }}>
          <Form>
            <div className="text-center pb-4">
              <img
                src={logo}
                className="rounded"
                style={{ width: 320, height: 90 }}
                alt="logo"
              />
            </div>
            <S.DivInput>
              <FormGroup row>
                <Col>
                  <S.CustomInput
                    bsSize="lg"
                    id="cod"
                    type="text"
                    name="cod"
                    onChange={(e) => setCod(e.target.value)}
                    value={cod}
                    mask="99999"
                    style={{
                      borderBottomColor: 'white',
                      borderBottomWidth: 1,
                    }}
                  />
                </Col>
              </FormGroup>
            </S.DivInput>
            <S.DivText>
              <S.TextLogin2>Dígite o código enviado para o email</S.TextLogin2>
              <S.TextLogin>{email}</S.TextLogin>
            </S.DivText>
            {erro && 
              <S.CustomFormText color={colorAlert}>
                {mensagem}
              </S.CustomFormText>
            }
            <Button size="lg" block color="#FFFFFF" style={{ border: '1px solid #FFFFFF', borderRadius: '20px', gap: '20px' }} onClick={() => handleValidSubmit()}>
              <img src={imgButton} alt="" style={{ marginRight: '10px' }} />
              <S.TextLogin>VALIDAR</S.TextLogin>
            </Button>
            {erro2 && 
              <S.CustomFormText color="green">
                {mensagem}
              </S.CustomFormText>
            }
            <S.EsqueciSenha onClick={() => handleFormSubmit()} >
              <S.CustomCadeado /><S.Link>Não recebi o código</S.Link>
            </S.EsqueciSenha>
          </Form>
        </Card>
      </Col>
      <Footer />
    </Row>
  );
}

export default RecuperarSenha;
