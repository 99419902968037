import styled from 'styled-components';
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import CPF from '../../assets/img/icones/CPF';
import { FormText } from 'reactstrap';

export const DivText = styled.div`
    display: flex;
    justify-content: center;
`;

export const TextLogin = styled.text`
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
`;

export const EsqueciSenha = styled.section`
    display: flex;
    width: 100%;
    height: 18px;
    gap: 10px;
    justify-content: right;
    align-items: right;
    margin-top: 10px;
`;

export const TextLabel = styled.text`
    font-size: 15px;
    color: #FFFFFF;
`;

export const CustomInput = styled(InputMask)`
    padding: 0.5em;
    margin: 0.5em;
    background: #3192D2;
    border-radius: 3px;
    color: #FFFFFF;
    border-style: solid;
    border-bottom-width: 1px;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
        background: #3192D2;
        color: #FFFFFF;
        border: 1px solid #3192D2;
    }
    
    &::-webkit-input-placeholder {
        color: #FFFFFF;
    }
`;

export const CustomLink = styled(Link)`
    text-decoration: none;
    color: #FFFFFF;
    font-size: 15px;

    &:hover {
        color: #FFFFFF;
    }
`;

export const DivInput = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CustomCPF = styled(CPF)`
    width: 40px;
    height: 40px;

    & path {
        fill: #000000;
        color: #000000;
    }
`;

export const CustomFormText = styled(FormText)`
    width: 100%;
    height: 50px;
    background-color: #D24451;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;