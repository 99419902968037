import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Sindicalizados from '../../assets/img/icones/Sindicalizados';
import Sindicatos from '../../assets/img/icones/Sindicatos';
import Agenda from '../../assets/img/icones/Agenda';
import Eventos from '../../assets/img/icones/Eventos';
import Juridico from '../../assets/img/icones/Juridico';
import perfil from '../../assets/img/icones/perfil.svg';
import config from '../../assets/img/icones/config.svg';
import ajuda from '../../assets/img/icones/ajuda.svg';
import {
  Nav,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import * as S from './styled';
import Footer from '../../components/Footer';
import Axios from '../../utils/Axios';


const Sidebar = () => {
  const [dados, setDados] = useState([]);
  const [dadosMenu, setDadosMenu] = useState([]);
  const bem = bn.create('sidebar');

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/permissoes/menu`).then((response) => {
      setDados(response.data)
    })
  }, []);

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/menu`).then((response) => {
      setDadosMenu(response.data)
    })
  }, []);

  const navItems2 = [
    { to: '/perfil', name: 'meu perfil', exact: true, Icon: perfil },
    { to: '/config', name: 'configuração de conta', exact: true, Icon: config },
    { to: '/ajuda', name: 'ajuda', exact: true, Icon: ajuda },
  ];

  return (
    <aside className={bem.b()} style={{ padding: '55px 0' }}>
        <div className={bem.e('background')} style={{ backgroundColor: '#000000'}} />
        <div className={bem.e('content')}>
          {/* <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo}
                width="200"
                height="60"
                className="pr-2"
                alt=""
              />
            </SourceLink>
          </Navbar> */}
          <S.Sombra>
            <Nav vertical>
            {dados !== undefined && dados.map((item) => (
              dadosMenu !== undefined && dadosMenu.map((itemMenu) => (
                itemMenu.permissao === item.permissao ?
                <NavItem className={bem.e('nav-item')} key={item.id}>
                  <BSNavLink
                    tag={NavLink}
                    to={itemMenu.caminho}
                    activeClassName="active"
                    exact={true}
                  >
                    {itemMenu.icone === "Sindicalizados" ? <Sindicalizados style={{ width:"32px", height:"32px" }} /> :
                      itemMenu.icone === "Sindicatos" ? <Sindicatos style={{ width:"32px", height:"32px" }} /> :
                      itemMenu.icone === "Agenda" ? <Agenda style={{ width:"32px", height:"32px" }} /> :
                      itemMenu.icone === "Eventos" ? <Eventos style={{ width:"32px", height:"32px" }} /> :
                      itemMenu.icone === "Juridico" ? <Juridico style={{ width:"32px", height:"32px" }} /> : ""
                    }
                    <S.Text style={{ marginLeft: '10px' }}>
                      <S.Title>{itemMenu.nome}</S.Title>
                      <S.SubTitle>{itemMenu.info}</S.SubTitle>
                    </S.Text>
                  </BSNavLink>
                </NavItem>
                : ""
              ))
            ))}
            </Nav>
          </S.Sombra>
          <S.Sombra2>
            <Nav vertical>
              {navItems2.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <img src={Icon} alt="" style={{ marginRight: '10px' }} />
                    <S.Text>
                      <S.Title>{name}</S.Title>
                    </S.Text>
                  </BSNavLink>
                </NavItem>
              ))}
            </Nav>
          </S.Sombra2>
          <Footer />
        </div>
      </aside>
  );
}

export default Sidebar;